import { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// import cloudinaryUrl from 'helpers/cloudinaryUrl'

import s from './CloudinaryVideo.module.css'

// NOTE: Cloudinary is becoming too expensive to use for the client.
// With so much media and primarily the bandwidth being too high we have to remove
// video assets being served for now.
function CloudinaryVideo({
  videos,
  videoAutoplay,
  videoLoop,
  videoEnded,
  className,
}) {
  if (videos?.length < 1) return null

  const [inProp, setInProp] = useState(false)

  return (
    <video
      className={classNames(s.container, className, {
        [s.loaded]: inProp === true,
      })}
      playsInline
      autoPlay={videoAutoplay === true}
      muted
      loop={videoLoop}
      // controls
      onEnded={() => videoEnded()}
      onLoadedData={() => setInProp(true)}
    >
      {/* <source src={cloudinaryUrl('video', url, params)} type={type} /> */}
      {videos.map(({ url, type }) => (
        <source key={url} src={url} type={type} />
      ))}
    </video>
  )
}

CloudinaryVideo.propTypes = {
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      type: PropTypes.string,
      params: PropTypes.string,
    })
  ),
  videoAutoplay: PropTypes.bool,
  videoLoop: PropTypes.bool,
  videoEnded: PropTypes.func,
  className: PropTypes.string,
}
CloudinaryVideo.defaultProps = {
  videos: [
    {
      url: '',
      type: '',
      params: '',
    },
  ],
  videoAutoplay: true,
  videoLoop: false,
  videoEnded: () => {},
  className: '',
}

export default CloudinaryVideo

// OLD way of adding videos before a quick change to not use Cloudinary.
// return videos.map(({ url, type, params }, i) => {
//   if (!url && !type) return null
//   return (
//     <video
//       key={`${url}${i}`}
//       className={classNames(s.container, className, {
//         [s.mobile]: videos.length > 1 && i === 0,
//         [s.desktop]: i === 1,
//         [s.loaded]: inProp === true,
//       })}
//       playsInline
//       autoPlay={videoAutoplay === true}
//       muted
//       loop={videoLoop}
//       // controls
//       onEnded={() => videoEnded()}
//       onLoadedData={() => setInProp(true)}
//     >
//       {/* <source src={cloudinaryUrl('video', url, params)} type={type} /> */}
//       <source src={url} type={type} />
//     </video>
//   )
// })
