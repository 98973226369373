import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'
import classNames from 'classnames'

import CloudinaryVideo from './CloudinaryVideo'
import CloudinaryImage from './CloudinaryImage'

import s from './CloudinaryMedia.module.css'

function CloudinaryMedia({
  videos,
  videoLoop,
  videoEnded,
  videoAutoplay,
  images,
  noLazy,
  className,
}) {
  let media = null

  if (videos?.[0]?.url) {
    media = (
      <CloudinaryVideo
        videos={videos}
        videoAutoplay={videoAutoplay}
        videoLoop={videoLoop}
        videoEnded={videoEnded}
      />
    )
  } else if (images?.[0]?.url) {
    media = <CloudinaryImage images={images} />
  }

  const content = (
    <div className={classNames(s.container, className)}>{media}</div>
  )

  return noLazy ? (
    content
  ) : (
    <LazyLoad offset={1000} once>
      {content}
    </LazyLoad>
  )
}

CloudinaryMedia.propTypes = {
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      type: PropTypes.string,
      params: PropTypes.string,
    })
  ),
  videoAutoplay: PropTypes.bool,
  videoLoop: PropTypes.bool,
  videoEnded: PropTypes.func,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
      params: PropTypes.string,
    })
  ),
  noLazy: PropTypes.bool,
  className: PropTypes.string,
}
CloudinaryMedia.defaultProps = {
  videos: [
    {
      url: '',
      type: '',
      params: '',
    },
  ],
  videoAutoplay: true,
  videoLoop: false,
  videoEnded: () => {},
  images: [
    {
      url: '',
      alt: '',
      params: '',
    },
  ],
  noLazy: false,
  className: '',
}

export default CloudinaryMedia
