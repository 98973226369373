import { CLOUDINARY_URL } from '@constants'
import isModellinkSite from './isModellinkSite';

export default function cloudinaryUrl(type, url, params) {
  const trimUrl = url
    .replace(
      /https:\/\/cms-dev*\.selectmodel\.com\/([^/]+\/)*wp-content\/uploads\//,
      ``
    )
    .replace(
      /https:\/\/cms-new*\.selectmodel\.com\/([^/]+\/)*wp-content\/uploads\//,
      ``
    )
    .replace(
      /https:\/\/cms-test*\.selectmodel\.com\/([^/]+\/)*wp-content\/uploads\//,
      ``
    )
    .replace(
      /https:\/\/([\w]+\.)?cms\.selectmodel\.com\/([^/]+\/)*wp-content\/uploads\//,
      ``
    )

  const folder = isModellinkSite() ? 'wp-modellink' : 'wp-select-model'

  const setParams = params ? `/${params}` : ''
  return `${CLOUDINARY_URL}/${type}/upload${setParams}/${folder}/${trimUrl}`
}
