import { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import cloudinaryUrl from 'helpers/cloudinaryUrl'

import s from './CloudinaryImage.module.css'

function CloudinaryImage({ images, className }) {
  if (images?.length < 1) return null

  const [inProp, setInProp] = useState(false)

  const setImage1 = images[0] || null
  const setImage2 = images[1] || null
  const setImage1Params = setImage1?.params || null
  const setImage2Params = setImage2?.params || null

  return (
    <picture
      className={classNames(s.container, className, {
        [s.loaded]: inProp === true,
      })}
      onLoad={() => setInProp(true)}
    >
      {/* <source srcSet={src} media="(min-width: 1440px)" /> */}
      {setImage2?.url && (
        <source
          srcSet={cloudinaryUrl('image', setImage2.url, setImage2Params)}
          media="(min-width: 900px)"
        />
      )}
      {setImage1?.url && (
        <img
          src={cloudinaryUrl('image', setImage1.url, setImage1Params)}
          alt={setImage1?.alt}
        />
      )}
    </picture>
  )
}

CloudinaryImage.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
      params: PropTypes.string,
    })
  ),
  className: PropTypes.string,
}
CloudinaryImage.defaultProps = {
  images: [
    {
      url: '',
      alt: '',
      params: '',
    },
  ],
  className: '',
}

export default CloudinaryImage
